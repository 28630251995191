// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kinetik-kraft-index-tsx": () => import("./../../../src/pages/kinetik/kraft/index.tsx" /* webpackChunkName: "component---src-pages-kinetik-kraft-index-tsx" */),
  "component---src-pages-kinetik-nett-index-tsx": () => import("./../../../src/pages/kinetik/nett/index.tsx" /* webpackChunkName: "component---src-pages-kinetik-nett-index-tsx" */),
  "component---src-pages-om-tsx": () => import("./../../../src/pages/om.tsx" /* webpackChunkName: "component---src-pages-om-tsx" */),
  "component---src-pages-tjenester-aktiverhan-index-tsx": () => import("./../../../src/pages/tjenester/aktiverhan/index.tsx" /* webpackChunkName: "component---src-pages-tjenester-aktiverhan-index-tsx" */),
  "component---src-pages-tjenester-elhubsok-index-tsx": () => import("./../../../src/pages/tjenester/elhubsok/index.tsx" /* webpackChunkName: "component---src-pages-tjenester-elhubsok-index-tsx" */),
  "component---src-pages-tjenester-konsulent-index-tsx": () => import("./../../../src/pages/tjenester/konsulent/index.tsx" /* webpackChunkName: "component---src-pages-tjenester-konsulent-index-tsx" */),
  "component---src-pages-type-2-tsx": () => import("./../../../src/pages/type2.tsx" /* webpackChunkName: "component---src-pages-type-2-tsx" */),
  "component---src-pages-varsler-tsx": () => import("./../../../src/pages/varsler.tsx" /* webpackChunkName: "component---src-pages-varsler-tsx" */)
}

