import React from "react";
import {Helmet} from "react-helmet";

function Index({ children, location }) {

    return (
        <>

            {children}
        </>
    );
}

export default Index;

/*
            <Helmet>
                <link rel="stylesheet" href="https://rsms.me/inter/inter.css" />
            </Helmet>
*/